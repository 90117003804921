<style lang="scss">
#app-user-detail {
  .close {
    display: none;
  }
}
</style>
<template>
  <b-modal
    id="app-user-detail"
    no-close-on-backdrop
    centered
    v-model="showModal"
    :title="edit ? 'Update User Detail' : 'Add User'"
  >
    <div class="form-group">
      <label for="">First Name <span class="text-danger">*</span></label>
      <input
        type="text"
        class="form-control"
        v-model="userDetails.first_name"
        :class="errors['first_name'] ? 'border border-danger' : ''"
      />
      <span v-if="errors['first_name']" class="text-danger">{{
        errors["first_name"][0]
      }}</span>
    </div>

    <div class="form-group">
      <label for="">Last Name <span class="text-danger">*</span></label>
      <input
        type="text"
        class="form-control"
        v-model="userDetails.last_name"
        :class="errors['last_name'] ? 'border border-danger' : ''"
      />
      <span v-if="errors['last_name']" class="text-danger">{{
        errors["last_name"][0]
      }}</span>
    </div>

    <div class="form-group">
      <label for="">Email Address<span class="text-danger">*</span></label>
      <input
        type="text"
        class="form-control"
        v-model="userDetails.email"
        :class="errors['email'] ? 'border border-danger' : ''"
      />
      <span v-if="errors['email']" class="text-danger">{{
        errors["email"][0]
      }}</span>
    </div>

    <div class="form-group" v-if="edit">
      <label for="">Username<span class="text-danger">*</span></label>
      <input
        type="text"
        class="form-control"
        v-model="userDetails.username"
        :class="errors['username'] ? 'border border-danger' : ''"
      />
      <span v-if="errors['username']" class="text-danger">{{
        errors["username"][0]
      }}</span>
    </div>
    <div class="form-group">
      <label for="exampleFormControlSelect1">Select Management Role</label>
      <select
        class="form-control"
        id="exampleFormControlSelect1"
        v-model="userDetails.roles"
      >
        <option value="admin">Admin</option>
        <option value="staff" selected>Staff</option>
      </select>
    </div>
    <div class="form-group" v-if="currentUser.u.is_service_provider">
      <label for="exampleFormControlSelect1">Select Live Scan role</label>
      <select
        class="form-control"
        id="exampleFormControlSelect1"
        @change="onRoleChange()"
        v-model="userDetails.user_role"
      >
        <option value="">None</option>
        <option
          v-for="(citem, index) in getRoleData.data"
          :key="index"
          :value="citem.id"
        >
          {{ citem.title }}
        </option>
      </select>
    </div>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          @click="close()"
          variant="danger"
          size="sm"
          class="float-left"
        >
          Close
        </b-button>

        <b-button
          v-if="edit"
          ref="kt_update_user"
          variant="success"
          size="sm"
          class="float-right"
          @click="updateUser()"
        >
          Update
        </b-button>
        <b-button
          v-else
          ref="kt_store_user"
          variant="success"
          size="sm"
          class="float-right"
          @click="storeUser()"
        >
          Save
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";

import {
  UPDATE_USER_DETAIL,
  ADD_USER_DETAIL
} from "@/core/services/store/actions.type";
// import { throws } from "assert";
export default {
  data() {
    return {
      errors: [],
      send_mail: true
    };
  },

  computed: {
    ...mapGetters(["currentUser", "getRoleData"])
  },
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    userDetails: {
      type: Object,
      default: function() {
        return [];
      }
    },
    edit: {
      type: Boolean,
      default: true
    },
    location_id: {
      type: Number,
      required: true
    }
  },

  methods: {
    close() {
      this.errors = [];
      this.$emit("closemodal", 0);
    },
    onRoleChange() {
      if (event.target.value == "null") {
        this.userDetails.user_role.value = null;
      }
    },
    storeUser() {
      const submitButton = this.$refs["kt_store_user"];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      this.userDetails.name =
        this.userDetails.first_name + " " + this.userDetails.last_name;
      this.errors = [];
      let type = "";
      if (this.currentUser.u.is_service_provider) {
        type = "sp";
      }
      if (this.currentUser.u.is_admin) {
        type = "admin";
      }

      this.$store
        .dispatch(ADD_USER_DETAIL, {
          send_mail: this.send_mail,
          name: this.userDetails.name,
          first_name: this.userDetails.first_name,
          last_name: this.userDetails.last_name,
          email: this.userDetails.email,
          location_id: this.location_id,
          type: type,
          roles: this.userDetails.roles,
          username: this.userDetails.email,
          user_role: this.userDetails.user_role
        })
        .then(data => {
          if (data.code == 202) {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
            submitButton.disabled = false;
            this.$swal({
              title: "Are you sure?",
              text: data.msg,
              buttons: true,
              dangerMode: true,
              showCancelButton: true,
              cancelButtonText: "No",
              confirmButtonText: "Yes"
            }).then(willDelete => {
              if (willDelete.isConfirmed) {
                this.send_mail = false;
                this.$store
                  .dispatch(ADD_USER_DETAIL, {
                    send_mail: this.send_mail,
                    name: this.userDetails.name,
                    first_name: this.userDetails.first_name,
                    last_name: this.userDetails.last_name,
                    email: this.userDetails.email,
                    location_id: this.location_id,
                    type: type,
                    roles: this.userDetails.roles,
                    username: this.userDetails.email,
                    user_role: this.userDetails.user_role
                  })
                  .then(data => {
                    this.$toastr.s(data.msg);
                    submitButton.classList.remove(
                      "spinner",
                      "spinner-light",
                      "spinner-right"
                    );
                    submitButton.disabled = false;
                    this.send_mail = true;
                    this.$emit("closemodal", 1);
                  })
                  .catch(err => {
                    this.errors = err;
                    this.send_mail = true;
                    // console.log(err);
                    submitButton.classList.remove(
                      "spinner",
                      "spinner-light",
                      "spinner-right"
                    );
                    submitButton.disabled = false;
                  });
              }
            });
          } else {
            this.$toastr.s(data.msg);
            this.send_mail = true;
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
            submitButton.disabled = false;
            this.$emit("closemodal", 1);
          }
        })
        .catch(err => {
          this.errors = err;
          this.send_mail = true;
          // console.log(err);
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
        });
    },
    updateUser() {
      const submitButton = this.$refs["kt_update_user"];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      this.userDetails.name =
        this.userDetails.first_name + " " + this.userDetails.last_name;
      this.errors = [];
      this.$store
        .dispatch(UPDATE_USER_DETAIL, {
          id: this.userDetails.id,
          name: this.userDetails.name,
          first_name: this.userDetails.first_name,
          last_name: this.userDetails.last_name,
          username: this.userDetails.username,
          email: this.userDetails.email,
          roles: this.userDetails.roles,
          user_role: this.userDetails.user_role
        })
        .then(data => {
          this.$toastr.s(data.msg);
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
          this.$emit("closemodal", 1);
        })
        .catch(err => {
          this.errors = err.error;
          // console.log(err);
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
        });
    }
  }
};
</script>
